<template>
<div class="form-group">
    <div class="col-lg-7 col-md-8 col-sm-8 col-xs-6 mx-auto">
        <div class="d-block row col-lg-8 mx-auto">
            <div class="">
                <OmniSelect :disabled="IsBillingInfoDisabled" class="omniipclass" v-model="Billing.BillingParty" :options="ICBillingPartyList" name="Billing Party" placeholder="Select Billing Party" toolTipText="<span>If the Agency owns the relationship with the client being requested, Direct should be selected.  If the relationship is owned by an Intercompany Agency, select from the IC list.</span>" @close-button-clicked="showAddProductModal()" @input="BillingChange()" />

                <p v-show="!BillingForm" class="d-flex justify-content-center Select-a-billing-par">
                    Select a billing party to display the rest of the form
                </p>
                <p v-show="!UnapprovedHFMcode" class="Dont-see-the-what">If you don't see the intercompany agency you're looking for,please
                    <span style="color:#00A1D2 !important;font-weight: 400;cursor:pointer;" @click="showAddProductModal()">Request a New Interagency Company</span>
                </p>
                <p v-show="UnapprovedHFMcode" class="Dont-see-the-what">You cannot submit this client request until this Intercompany Agency Request has been completed.Please save this form later.
                </p>
            </div>

            <div v-show="BillingForm">
                <div class="fieldborder">
                    <label for="billtoclientname" class="lbltxt">BILL TO CLIENT NAME</label>
                    <b-form-input autocomplete="off" id="billtoclientname" class="omniipclass" v-model="Billing.BillToClientName" placeholder="Enter bill to Client Name" :maxlength="20"></b-form-input>
                </div>

                <div class="fieldborder">
                    <label for="attn" class="lbltxt">ATTN</label>
                    <span style="margin-left: 124px" class="Select-a-billing-par">Optional</span>
                    <b-form-input autocomplete="off" id="attn" class="omniipclass" v-model="Billing.Attn" placeholder="Enter Attn"></b-form-input>
                </div>

                <div class="" style="padding-top: 16px !important">
                    <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Country" name="COUNTRY" :showLabel="true" label="Country" v-model="Billing.Country" :options="Countryilst"></OmniSelect>
                </div>

                <div class="fieldborder" v-if="!IsCountryUSA">
                    <label for="clientbillingstreetaddress" v-if="!IsCountryUSA" class="lbltxt">CLIENT BILLING STREET ADDRESS</label>
                    <b-form-input autocomplete="off" id="BillingAd1" class="omniipclass" v-if="!IsCountryUSA" v-model="Billing.AddressLine1" placeholder="Address Line 1" :maxlength="26"></b-form-input>
                </div>

                <div class="fieldborder" v-if="!IsCountryUSA">
                    <b-form-input autocomplete="off" id="BillingAd2" class="omniipclass" v-if="!IsCountryUSA" v-model="Billing.AddressLine2" :maxlength="26" placeholder="Address Line 2"></b-form-input>
                </div>
                <div class="fieldborder" v-if="IsCountryUSA">
                    <label for="city" class="lblstreet" v-if="IsCountryUSA">Street Address</label>
                    <b-form-input autocomplete="off" id="billinginterStAd" class="omniipclass" v-if="IsCountryUSA" v-model="Billing.StreetAddress" placeholder="Enter Street Address"></b-form-input>
                </div>

                <div class="fieldborder" v-if="IsCountryUSA">
                    <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                    <b-form-input autocomplete="off" id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="Billing.City" placeholder="Enter City" :maxlength="30"></b-form-input>
                </div>

                <div class="row fieldborder omniipclass" v-if="IsCountryUSA" style="
              padding-bottom: 69px !important;
              padding-top: 18px !important;
            ">
                    <div class="col-md-8">
                        <OmniSelect class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="STATE" :showLabel="true" label="STATE" :showSearch="true" v-model="Billing.BillingState" :options="Stateilst"></OmniSelect>
                    </div>
                    <div class="col-md-4" style="margin-top: -26px !important">
                        <label v-if="IsCountryUSA" for="zipcode" class="lbltxt">ZIP CODE</label>

                        <b-form-input autocomplete="off" id="zipcode" class="" :minlength="5" v-if="IsCountryUSA" style="width: 135px !important" :maxlength="5" v-bind:class="{
                  redtextbox: (validationtextbox||!$v.Billing.BillingZipCode.numeric) && $v.Billing.BillingZipCode.$dirty,
                  notredtextbox: !validationtextbox && $v.Billing.BillingZipCode.numeric,
                }" @blur="SetDirty('BillingZipCode')" @focus="ResetDirty('BillingZipCode')" @keyup="ValidateZipCode()" v-model="Billing.BillingZipCode" placeholder="Enter Zip Code"></b-form-input>
                        <div v-show="validationtextbox && $v.Billing.BillingZipCode.$dirty" style="width:135px;" class="error-message-format">
                            Input must be 5 characters
                        </div>
                        <div v-show="!$v.Billing.BillingZipCode.numeric && $v.Billing.BillingZipCode.$dirty" style="width:135px;" class="error-message-format">
                            This field can only contain numerical values
                        </div>
                    </div>

                </div>

                <div class="mx-auto col-lg-12" style="
              padding-left: 5px;
              background-color: rgba(216, 216, 216, 0.2) !important;
            " v-if="Showaddressbillingexception">
                    <div>
                        <span class="SUGGESTED-ADDRESS"> SUGGESTED STREET ADDRESS </span>
                        <br />
                        <span class="Due-to-character-res">
                            Due to character restrictions, we suggest the use of the
                            following address:
                        </span>
                    </div>
                    <!-- <span class="SUGGESTED-ADDRESS">Suggested Address</span>
             <span class="Due-to-character-res">Due to character restrictions, we suggest the use of the following address:</span>              -->
                    <div class="fieldborder" v-if="IsCountryUSA">
                        <label for="city" class="lblstreet" v-if="IsCountryUSA">Street Address</label>
                        <b-form-input autocomplete="off" id="billinginterStAd" class="omniipclass" v-if="IsCountryUSA" v-model="Billing.TruncatedBillingStreetAddress" placeholder="Enter Street Address" :maxlength="26"></b-form-input>
                    </div>

                    <div class="fieldborder" v-if="IsCountryUSA">
                        <label for="city" class="lbltxt" v-if="IsCountryUSA">CITY</label>
                        <b-form-input autocomplete="off" id="billingCity" class="omniipclass" v-if="IsCountryUSA" v-model="Billing.City" placeholder="Enter City" :maxlength="30"></b-form-input>
                    </div>

                    <div class="row fieldborder omniipclass" v-if="IsCountryUSA" style="
                padding-bottom: 69px !important;
                padding-top: 18px !important;
              ">
                        <div class="col-md-8">
                            <OmniSelect class="pt-0 omniindexclass omniindexclass" v-if="IsCountryUSA" placeholder="Select State" name="STATE" :showLabel="true" label="STATE" :showSearch="true" v-model="Billing.BillingState" :options="Stateilst"></OmniSelect>
                        </div>
                        <div class="col-md-4" style="margin-top: -26px !important">
                            <label v-if="IsCountryUSA" for="zipcode" class="lbltxt">ZIP CODE</label>

                            <b-form-input autocomplete="off" id="zipcode" class="" :minlength="5" v-if="IsCountryUSA" style="width: 135px !important" :maxlength="10" v-bind:class="{
                    redtextbox: validationtextbox,
                    notredtextbox: !validationtextbox,
                  }" @keyup="ValidateZipCode()" v-model="Billing.BillingZipCode" placeholder="Enter Zip Code"></b-form-input>
                        </div>
                    </div>
                    <p v-show="validationtextbox" class="zipcodevalidationmsg">
                        Minimum 5 characters required.
                    </p>
                </div>
                <div class="fieldborder" style="padding-top: 18px">
                    <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Currency" name="CURRENCY" :showLabel="true" label="name" v-model="Billing.Currency" :options="CurrencyList"></OmniSelect>
                </div>
                <div class="fieldborder" style="padding-top: 24px">
                    <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select Payment Terms" name="DEFAULT PAYMENT TERMS" :showLabel="true" label="DEFAULT PAYMENT TERMS" v-model="Billing.DfltPayment" :options="PaymentTermsList" toolTipText="<span>This dictates the due date on invoices billed against the client. Any new or existing clients that have payment terms above N030, require network CFO approval. Pls attach the mandatory Screenshot from Credit Voyager.</span>"></OmniSelect>
                </div>
                <div class="fieldborder" style="" v-if="ShowScreenshot">
                <label class="lbltxt">Approval Screenshot from Credit Voyager</label>
                <vue-dropzone v-on:vdropzone-mounted="mountedmyVueDropzone" :duplicateCheck="true" ref="screenshotVueDropzone"
                    id="screenshotVueDropzone" :useCustomSlot="true" class="customBillingInfoDropzone" :include-styling="false"
                    :options="dropzoneOptions" v-on:vdropzone-file-added="ScreenshotUploadedToDropzone"
                    v-on:vdropzone-removed-file="ScreenshotRemovedFromDropzone"
                >
                    <div class="dropzone-custom-content">
                    <p class="Drag-and-drop-file-h">
                        <b-img
                        :src="closeFilterIcon"
                        style="
                            margin-top: -7px !important;
                            font-family: 'Open Sans';
                        "
                        />
                        Drag and drop file here or
                        <span class="draganddropspan"> browse </span>
                    </p>
                    </div>
                </vue-dropzone>
                </div>
                <!-- <br> -->
                <div class="col-md-12">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                            Is this a cos2 client?
                        </span>
                        <svg style="margin-bottom: 1px; margin-left: 5px" width="9px" height="9px" viewBox="0 0 9 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" v-b-tooltip.hover.v-dark.right.html="
                  'COS2 (markup) is used in MediaOcean for undisclosed revenue models for billing.'
                ">
                            <g id="LATEST" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                <path d="M4.5,0 C2.014875,0 0,2.014875 0,4.5 C0,6.985125 2.014875,9 4.5,9 C6.985125,9 9,6.985125 9,4.5 C8.997375,2.016 6.984,0.002625 4.5,0 Z M4.59375,1.875 C4.90425,1.875 5.15625,2.127 5.15625,2.4375 C5.15625,2.748 4.90425,3 4.59375,3 C4.28325,3 4.03125,2.748 4.03125,2.4375 C4.03125,2.127 4.28325,1.875 4.59375,1.875 Z M5.4375,6.9375 L3.9375,6.9375 C3.7305,6.9375 3.5625,6.7695 3.5625,6.5625 C3.5625,6.3555 3.7305,6.1875 3.9375,6.1875 L4.21875,6.1875 C4.2705,6.1875 4.3125,6.1455 4.3125,6.09375 L4.3125,4.40625 C4.3125,4.3545 4.2705,4.3125 4.21875,4.3125 L3.9375,4.3125 C3.7305,4.3125 3.5625,4.1445 3.5625,3.9375 C3.5625,3.7305 3.7305,3.5625 3.9375,3.5625 L4.3125,3.5625 C4.726875,3.5625 5.0625,3.898125 5.0625,4.3125 L5.0625,6.09375 C5.0625,6.1455 5.1045,6.1875 5.15625,6.1875 L5.4375,6.1875 C5.6445,6.1875 5.8125,6.3555 5.8125,6.5625 C5.8125,6.7695 5.6445,6.9375 5.4375,6.9375 Z" id="info" fill="#00A1D2" fill-rule="nonzero"></path>
                            </g>
                        </svg>
                    </slot>
                    <b-form-group label="" class="timeonly-text">
                        <b-form-radio-group class="omniipclass" id="isCostToClient" v-model="Billing.IsThisCostToClient" name="isCostToClient">
                            <b-form-radio value="true">Yes</b-form-radio>
                            <b-form-radio value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div class="col-md-12" v-if="CostoClient">
                    <slot name="label" class="lbltxt">
                        <span class="lbltxt" style="margin-left: -2px; text-transform: uppercase">
                            Is this client COS2 ONLY for media B- Programmatic Bundled?
                        </span>
                    </slot>
                    <b-form-group label="" class="timeonly-text">
                        <b-form-radio-group class="omniipclass" id="programatic" v-model="Billing.ProgrammaticBundled" name="programatic">
                            <b-form-radio value="true">Yes</b-form-radio>
                            <b-form-radio value="false">No</b-form-radio>
                        </b-form-radio-group>
                    </b-form-group>
                </div>
                <div v-if="ShowAOR" style="margin-top: -15px">
                    <div class="fieldborder">
                        <label for="AORclientcode" class="lbltxt">AOR CLIENT CODE</label>
                        <b-form-input autocomplete="off" id="AORClientCode" class="omniipclass" v-model="Billing.AORClientCode" placeholder="Enter AOR Client Code"></b-form-input>
                    </div>
                    <div class="fieldborder">
                        <label for="AORclientname" class="lbltxt">AOR CLIENT NAME</label>
                        <b-form-input autocomplete="off" id="AORClientName" class="omniipclass" v-model="Billing.AORClientName" placeholder="Enter AOR Client Name"></b-form-input>
                    </div>
                    <div class="fieldborder" style="padding-top: 24px">
                        <OmniSelect class="pt-0 omniipclass omniindexclass" placeholder="Select AOR Client Agency" name="AOR CLIENT AGENCY" :showLabel="true" label="AOR CLIENT AGENCY" v-model="Billing.AORClientAgency" :options="EntityList"></OmniSelect>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <br />
    <div class="form-group pt-60 text-right footer-buttons">
        <button class="btn btn-link text-uppercase" @click="Cancel()">
            CANCEL
        </button>
        <button class="text-uppercase btn btn-secondary btn-primary" @click="SaveasDraft()">
            SAVE FOR LATER
        </button>
        <button class="text-uppercase btn custom-button btn-primary" @click="NavigateToEular()">
            BACK
        </button>
        <button class="text-uppercase btn custom-button btn-primary" :disabled="!ShowAddProduct && this.$v.$invalid" @click="AddClientBillingInfo()">
            NEXT
        </button>

    </div>
    <!-- <br> -->
    <b-modal size="xl" class="Title-Text" v-model="ShowAddProduct" hide-footer title="Request New Intercompany Agency">
        <p class="You-cannot-submit-th">
            Please note that you cannot submit the new client form before the new
            interagency request is approved however you may save the form and submit
            later.
        </p>
        <div class="d-block row">
            <div class="fieldborder col-lg-10 mx-auto">
                <label for="hfmentitycode" style="text-transform: uppercase" class="lbltxt">HFM Entity Code</label>
                <b-form-input autocomplete="off" class="omniippopupclass" id="hfmentitycode" v-model="BillingPopup.HfmEntityCode" placeholder="Enter HFM Entity Code" @blur="CheckHFMCodeExist()"></b-form-input>
                <p v-if="IsCodeExist" class="error-message-format">This HFM code already exists.</p>
            </div>

            <div class="fieldborder col-lg-10 mx-auto">
                <label for="intercompanyagencyname" style="text-transform: uppercase" class="lbltxt">InterCompany Agency Name</label>
                <b-form-input ref="billingpopupfield" autocomplete="off" class="omniippopupclass" id="intercompanyagencyname" v-model="BillingPopup.InterCompanyAgencyName" placeholder="Enter InterCompany Agency Name"></b-form-input>
            </div>

            <div class="fieldborder col-lg-10 mx-auto" style="padding-top: 18px">
                <OmniSelect class="pt-0 omniippopupclass omniindexclass" placeholder="Select Intercompany Network/Region" name="InterCompany Agency Network/Region" :showLabel="true" label="Intercompany Network/Region" v-model="BillingPopup.InterAgencyNw" :options="NetworkRegion"></OmniSelect>
            </div>

            <div class="col-lg-10 mx-auto">
                <OmniSelect class="pt-0 omniippopupclass omniindexclass" placeholder="Select Country" name="Country" :showLabel="true" label="Country" v-model="BillingPopup.Country" :options="Countryilst"></OmniSelect>
            </div>

            <div class="fieldborder col-lg-10 mx-auto" v-if="!IsPopupCountryUSA">
                <label for="intercompanyagencyStAd" style="text-transform: uppercase" v-show="!IsPopupCountryUSA" class="lbltxt">Address Line1</label>
                <b-form-input autocomplete="off" class="omniippopupclass" v-model="BillingPopup.InterAgencyStreetAddressLine1" v-show="!IsPopupCountryUSA" :maxlength="26" id="intercompanyagencystreetaddress" placeholder="Enter Address Line1"></b-form-input>
            </div>

            <div class="fieldborder col-lg-10 mx-auto" v-if="!IsPopupCountryUSA">
                <label for="intercompanyagencyStAd" style="text-transform: uppercase" v-show="!IsPopupCountryUSA" class="lbltxt">Address Line2</label>
                <b-form-input autocomplete="off" class="omniippopupclass" v-model="BillingPopup.InterAgencyStreetAddressLine2" v-show="!IsPopupCountryUSA" :maxlength="26" id="intercompanyagencystreetaddress" placeholder="Enter Address Line2"></b-form-input>
            </div>

            <!-- <br v-if="IsPopupCountryUSA"> -->
            <div class="fieldborder col-lg-10 mx-auto" v-if="IsPopupCountryUSA">
                <label for="intercompanyagencyStAd" style="text-transform: uppercase" v-show="IsPopupCountryUSA" class="lbltxt">InterComapany Agency Street Address</label>
                <b-form-input autocomplete="off" class="omniippopupclass" v-model="BillingPopup.InterAgencyStreetAddress" v-show="IsPopupCountryUSA" id="intercompanyagencystreetaddress" :maxlength="26" placeholder="Select InterComapany Agency Street Address"></b-form-input>
            </div>

            <div class="fieldborder col-lg-10 mx-auto" v-if="IsPopupCountryUSA">
                <label for="intercompanyagencyCy" style="text-transform: uppercase" v-show="IsPopupCountryUSA" class="lbltxt">City</label>
                <b-form-input autocomplete="off" class="omniippopupclass" v-model="BillingPopup.InterAgencyCitys" id="intercompanyagencycity" v-show="IsPopupCountryUSA" placeholder="Enter City" :maxlength="30"></b-form-input>
            </div>

            <div v-if="IsPopupCountryUSA" class="row fieldborder omniiaddressppopupclass col-lg-10 mx-auto" style="padding-bottom: 69px !important; padding-top: 18px">
                <div class="col-md-7" style="margin-left: -18px !important" v-show="IsPopupCountryUSA">
                    <OmniSelect class="pt-0 omniindexclass" placeholder="Select State" name="STATE" :showLabel="true" :showSearch="false" label="STATE" v-model="BillingPopup.States" :options="Stateilst"></OmniSelect>
                </div>
                <div class="col-md-4" style="margin-left: -26px !important; margin-top: -26px !important" v-show="IsPopupCountryUSA">
                    <label for="zipcode" class="lbltxt">ZIP CODE</label>
                    <b-form-input style="width: 129px !important" autocomplete="off" :maxlength="5" :minlength="5" v-model="BillingPopup.ZipCodes" v-bind:class="{
                    errorborder: (popupvalidationtextbox ||!$v.BillingPopup.ZipCodes.numeric) && $v.BillingPopup.ZipCodes.$dirty,
                    noterrorborder: !popupvalidationtextbox && $v.BillingPopup.ZipCodes.numeric,
                  }" @keyup="ValidatePopupZipCode()" @blur="$v.BillingPopup.ZipCodes.$touch()" @focus="ResetDirtyBillingPopup('ZipCodes')" id="zipcode" placeholder="Enter Zip Code"></b-form-input>
                    <div v-show="popupvalidationtextbox && $v.BillingPopup.ZipCodes.$dirty" style="width:135px;" class="error-message-format">
                        Input must be 5 characters
                    </div>
                    <div v-show="!$v.BillingPopup.ZipCodes.numeric && $v.BillingPopup.ZipCodes.$dirty" style="width:135px;" class="error-message-format">
                        This field can only contain numerical values
                    </div>
                </div>
            </div>
            <div class="fieldborder col-lg-10 mx-auto">
                <label for="intercompanyagencycontact" style="text-transform: uppercase" class="lbltxt">Intercompany Agency Contact</label>
                <b-form-input autocomplete="off" class="omniippopupclass" v-model="BillingPopup.InterAgencyContact" id="intercompanyagencycontact" :minlength="99" placeholder="Enter Intercompany Agency Contact"></b-form-input>
            </div>

            <div class="fieldborder col-lg-10 mx-auto">
                <label for="intercompanyagencycontactemail" style="text-transform: uppercase" class="lbltxt">Intercompany Agency Contact Email</label>

                <b-form-input autocomplete="off" id="icagencycontactemail" class="omniipclassinteragencyemail" v-if="true" v-model="BillingPopup.InterAgencyContactEmail" :maxlength="100" placeholder="Enter InterCompany Agency Contact Email" v-bind:class="{
                    errorborder: !$v.BillingPopup.InterAgencyContactEmail.email && $v.BillingPopup.InterAgencyContactEmail.$dirty,
                    noterrorborder: $v.BillingPopup.InterAgencyContactEmail.email,
                  }" @blur="$v.BillingPopup.InterAgencyContactEmail.$touch()" @focus="ResetDirtyBillingPopup('InterAgencyContactEmail')"></b-form-input>
                <div class="error-message-format" v-if="
              !$v.BillingPopup.InterAgencyContactEmail.email &&
              $v.BillingPopup.InterAgencyContactEmail.$dirty
            ">
                    Please enter a valid email address (eg. test@FinSys.com)
                </div>

            </div>

            <div class="fieldborder col-lg-10 mx-auto">
                <label for="axlocatoin" class="lbltxt">Are there anything additional instructions?</label>
                <b-form-textarea id="textarea" placeholder="IE: Existing Customer, Main account rollup, etc." v-model="BillingPopup.AdditionalInstructions" rows="8" max-rows="8"></b-form-textarea>
            </div>

            <div class="fieldborder col-lg-10 mx-auto">
                <vue-dropzone v-model="BillingPopup.Files" :duplicateCheck="true" ref="myVueDropzone" id="dropzone" :useCustomSlot="true" class="customdropzone" :include-styling="false" :options="dropzoneOptions" v-on:vdropzone-file-added="UploadedtoDropzone" v-on:vdropzone-success="addAnalysisId" v-on:vdropzone-error="Uploaderror" v-on:vdropzone-removed-file="RemovedFromDropzone">
                    <div class="dropzone-custom-content">
                        <p class="Drag-and-drop-file-h">
                            <b-img :src="closeFilterIcon" style="
                        margin-top: -7px !important;
                        font-family: 'Open Sans';
                      " />
                            Drag and drop file here or
                            <span class="draganddropspan"> browse </span>
                        </p>
                    </div>
                </vue-dropzone>
            </div>

        </div>
        <br>
        <br>
        <br>
        <br>

        <div class="form-group pt-30 text-right">
            <button class="btn btn-link text-uppercase" @click="CancelAdd()">
                Cancel
            </button>
            <button class="text-uppercase btn custom-button btn-primary" :disabled="(ShowAddProduct && this.$v.$invalid) || (this.IsCodeExist || forminvalid()  )" @click="AddProduct()">
                Submit
            </button>
        </div>
    </b-modal>
    <b-modal v-model="ShowBillingPartyConfirmation" hide-footer :no-close-on-backdrop="true">
        <div>
            <p class="modelmessage">
                Changing billing parties will result in the address fields being
                reset.
            </p>
        </div>
        <template v-slot:modal-header="{ close }">
            <h5 class="modal-title">Are you sure you want to proceed</h5>
            <button class="close" @click="AddressResetCancel()"></button>
        </template>

        <div class="text-right">
            <button class="btn btn-link text-uppercase" @click="AddressResetCancel()">
                Cancel
            </button>
            <button class="text-uppercase btn custom-button btn-primary" @click="AddressResetConfrim()">
                OK
            </button>
        </div>
    </b-modal>
</div>
</template>

<script>
import store from "@/store";
import {
    requiredIf,
    maxLength,
    minLength,
    numeric,
    email
} from "vuelidate/lib/validators";
import {
    mapGetters
} from "vuex";
import service from "../../services/Dropdowns";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import { EntityNames,AgencyNames,EntitiesAlongWithAgency} from "../../utilities/CommonConst";
export default {
    validations: {
        Billing: {
            BillToClientName: {
                required: requiredIf(function () {
                    return !this.ShowAddProduct;
                }),
                maxLength: maxLength(20),
            },
            IsThisCostToClient: {
                required: requiredIf(function () {
                    return !this.ShowAddProduct;
                }),
            },
            ProgrammaticBundled: {
                required: requiredIf(function (model) {
                    return model.IsThisCostToClient == "true" && !this.ShowAddProduct;
                }),
            },

            AddressLine1: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country != "78d70193e1eb493dafcf3f261b7759ef"
                    );
                }),
            },
            AddressLine2: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country != "78d70193e1eb493dafcf3f261b7759ef"
                    );
                }),
            },
            StreetAddress: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country == this.CountryUnitedStatesValue
                    );
                }),
            },
            City: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country == this.CountryUnitedStatesValue
                    );
                }),
            },
            BillingState: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country == this.CountryUnitedStatesValue
                    );
                }),
            },
            TruncatedBillingStreetAddress: {
                required: requiredIf(function () {
                    return this.Showaddressbillingexception && !this.ShowAddProduct;
                }),
                maxLength: maxLength(26),
            },
            BillingZipCode: {
                required: requiredIf(function (model) {
                    return (
                        !this.ShowAddProduct &&
                        model.Country == this.CountryUnitedStatesValue
                    );
                }),
                numeric,
                maxLength: maxLength(5),
                minLength: minLength(5),
            },

            Currency: {
                required: requiredIf(function () {
                    return !this.ShowAddProduct;
                }),
            },

            DfltPayment: {
                required: requiredIf(function () {
                    return !this.ShowAddProduct;
                }),
            },

            AORClientCode: {
                required: requiredIf(function () {
                    return this.ShowAOR;
                }),
            },

            AORClientName: {
                required: requiredIf(function () {
                    return this.ShowAOR;
                }),
            },
            AORClientAgency: {
                required: requiredIf(function () {
                    return this.ShowAOR;
                }),
            },
            Files: {
                minLength: minLength(1),
                required: requiredIf(function () {
                    return this.ShowScreenshot;
                })
            }
        },
        BillingPopup: {
            HfmEntityCode: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
            },

            InterCompanyAgencyName: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
            },

            InterAgencyNw: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
            },

            Country: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
            },

            InterAgencyStreetAddressLine1: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == false;
                }),
            },

            InterAgencyStreetAddressLine2: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == false;
                }),
            },

            InterAgencyStreetAddress: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == true;
                }),
            },

            InterAgencyCitys: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == true;
                }),
            },

            ZipCodes: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == true;
                }),
                numeric,
                minLength: minLength(5),
                maxLength: maxLength(5)
            },

            States: {
                required: requiredIf(function () {
                    return this.ShowAddProduct && this.IsPopupCountryUSA == true;
                }),
            },

            InterAgencyContact: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
            },

            InterAgencyContactEmail: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                }),
                email,
                maxLength: maxLength(100)
            },

            AdditionalInstructions: {
                required: requiredIf(function () {
                    return this.ShowAddProduct;
                })
            }
        },
    },
    data() {
        return {

            CostoClient: false,
            IsBillingInfoDisabled: false,
            isdestroying: false,
            ICRequestId: 0,
            ddl: 0,
            CurrentFilesList: [],
            RemoveFileList: [],
            BillingInfo: {},
            clientForm: {},
            UnapprovedHFMcode: false,
            EntityList: [],
            IsCodeExist: false,
            Showaddressbillingexception: false,
            TooltipInfoIcon: require("@/assets/Images/infoicon.svg"),
            validationtextbox: false,
            popupvalidationtextbox: false,
            ShowOmnetFields: false,
            ShowBillingPartyConfirmation: false,
            BillingForm: false,
            NonUs: false,
            BillingPopup: {},
            ShowAddProduct: false,
            billingparty: null,
            costoclient: null,
            NetworkRegion: [],
            deleteIcon: require("@/assets/Icons/delete.svg"),
            closeFilterIcon: require("../../assets/Icons/file.png"),
            FilesUploaded: false,
            ShowScreenshot: false,
            dropzoneOptions: {
                // params: {'fileuploadId': this.ICRequestId },
                previewTemplate: this.template(),
                url: `${process.env.VUE_APP_API_ROOT}api/v1/InterCompany/ICDraftUpload`,
                uploadMultiple: true,
                maxFiles: 5,
                minFiles: 1,
                maxFilesize: 10,
                addRemoveLinks: false,
                parallelUploads: 5,
                createImageThumbnails: false,
                autoProcessQueue: false,
                //  headers:{"Authorization":'Bearer eyJraWQiOiJRS2V5YUxEY2RuQm9YKzlibm4rTGJQbjQrMVhiMjFuR2hHQ0l2aHpPQmJrPSIsImFsZyI6IlJTMjU2In0.eyJhdF9oYXNoIjoicldwdUNRbV9PenBvZjhwUG1iOFg4QSIsInN1YiI6IjgzNWU1NGQxLWY4YzMtNDJiYy1hZGQ2LTI4Y2RmZjdiNTc4ZiIsImNvZ25pdG86Z3JvdXBzIjpbImV1LXdlc3QtMV9yemluaUtwNzJfT01HLUFERlMiXSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLmV1LXdlc3QtMS5hbWF6b25hd3MuY29tXC9ldS13ZXN0LTFfcnppbmlLcDcyIiwiY29nbml0bzp1c2VybmFtZSI6Ik9NRy1BREZTX2NoYW5kcnUucG9ubnVzYW15QGFubmFsZWN0LmNvbSIsImdpdmVuX25hbWUiOiJDaGFuZHJ1IiwiYXVkIjoiNzM3MGlzOXZnb2Ewb2g2OXR2MHY2dDAxdXUiLCJpZGVudGl0aWVzIjpbeyJ1c2VySWQiOiJjaGFuZHJ1LnBvbm51c2FteUBhbm5hbGVjdC5jb20iLCJwcm92aWRlck5hbWUiOiJPTUctQURGUyIsInByb3ZpZGVyVHlwZSI6IlNBTUwiLCJpc3N1ZXIiOiJodHRwOlwvXC9vbWdzc28ub25lb21nLmNvbVwvYWRmc1wvc2VydmljZXNcL3RydXN0IiwicHJpbWFyeSI6InRydWUiLCJkYXRlQ3JlYXRlZCI6IjE2MDE5OTU4MzIxMTEifV0sInRva2VuX3VzZSI6ImlkIiwiYXV0aF90aW1lIjoxNjA3MzM4MjM0LCJleHAiOjE2MDkzMDg5MzUsImlhdCI6MTYwOTMwNTMzNSwiZmFtaWx5X25hbWUiOiJQb25udXNhbXkifQ.A66H0Vuv-pxNRKKOvG6BXsu4bK-Q0NfI5ORK8A8F8Vc59-RwHnuGc4tOL9TZT215YGA8kAh3o4WVs0YMFwXX5hmt7jKOCKKQyCjDhoLMPKNDVZ5eWULEoXVqHky5ZewvVc5y3Evo4rLsfHnyT1OC4j24oc8lwFFOshnqGGzkVNQ8zE4z39MdlioC9zQVxSzaxbAbnyol5qHQWQMXFhLHZOlrQ_5qxYirGdeZd0W7gdY6W8Asqj6thQ37tLf9537B6H54UKPxVeQYzxazbgsWqOFYs8jcMVTxI8dEIK25V-LgPf5Lba3BhqqaVGU_kVcPHH0qzmtRr38tfMIsZi-2Bg'},
                // headers: { "My-Awesome-Header": "header value", GUID: localStorage.getItem("UploadfileId") },
                init: function () {
                    // this.on("maxfilesexceeded", function (file) {
                    //   this.removeFile(file);
                    //   alert("Maximum File Upload limit is 5!");
                    // });

                    this.on("addedfile", function (file) {

                        if (this.files.length > 5) {
                            this.removeFile(file);
                            window.alert('Maximum File Upload limit is 5!');
                        }
                    });

                    this.on("removedfile", function () {
                        //       //this.Removefromserver(file.name);
                        //       //alert(file.name);
                        //        let filenamArray=[];
                        // var serverFilles=this.clientinfopagetwo.Files;
                        // alert(JSON.stringify(filenamArray));
                        // for(var i=0; i <serverFilles.length; i++)
                        // {
                        //     filenamArray.push(serverFilles[i].upload.filename);
                        // }
                        // if(filenamArray.includes('Hololens'))
                        // {
                        //    alert('call server');
                        // }
                        // else
                        // {
                        //   alert('remove from client');
                        // }
                    });
                },
            },

            options: [{
                    value: "Direct",
                    label: "Direct",
                },
                {
                    value: "interagency1",
                    label: "interagency1",
                },
                {
                    value: "interagency2",
                    label: "interagency2",
                },
                {
                    value: "interagency3",
                    label: "interagency3",
                },
            ],

            billinglist: [{
                    label: "Direct",
                    value: "Direct"
                },
                {
                    label: "interagency1 ",
                    value: "interagency1"
                },
                {
                    label: "interagency2",
                    value: "interagency2"
                },
                {
                    label: "interagency3",
                    value: "interagency3"
                },
            ],
            Countryilst: [],

            CurrencyList: [],

            CountryUnitedStatesValue: "78d70193e1eb493dafcf3f261b7759ef",
        };
    },
    props: {
        id: {
            type: Number,
            required: false,
        },
        isDraft: {
            type: Boolean,
            required: false
        }
    },
    components: {
        vueDropzone: vue2Dropzone,
    },
    mounted() {
        //window.console.log('test ounted bill');
        if (this.Billing.TruncatedBillingStreetAddress != null && this.Billing.TruncatedBillingStreetAddress.length > 0) {
            this.Showaddressbillingexception = true;
        }
        if (this.IsEdit == true && this.clientinfopageone.IsEditDataLoaded == true) {
            this.ManuallyUploadFiles(this.Billing.Files);
        }
        //window.console.log('test ounted bill');

    },
    activated() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
        if (this.ShowAOR) {
            this.LoadEntityDropdownValues();
        }
    },
    created() {
        this.$parent.$emit("stepInitiated", this.$route.meta.tabIndex);
        this.InitiateObject();
        this.LoadDropdownValues();
        this.LoadNetworkRegion();
        if (this.id && this.isDraft != true) {
            this.BillingForm = true;
            if (
                this.ICBillingPartyList == null ||
                this.ICBillingPartyList.length < 1
            ) {
                this.LoadBillingParty();
            }
        }
        if (this.id > 0 && this.Billing.IsThisCostToClient) {
            this.CostoClient = true;
        } else {
            this.CostoClient = false;
        }
        if (this.id > 0 && this.CanShowScreenshotAttachment(this.Billing.DfltPayment)) {
            this.ShowScreenshot = true;
        } else {
            this.ShowScreenshot = false;
        }
        //window.console.log(`test data ${this.Billing.BillingParty}`);
        //window.console.log(`test data id is ${this.id} and draft is ${this.isDraft}`);

        if (this.id && this.isDraft == true) {

            if (this.Billing.BillingParty.indexOf('(') > -1 && this.Billing.BillingParty.indexOf(')') > -1) {
                this.ICBillingPartyList.push({
                    label: this.Billing.BillingParty,
                    value: this.Billing.BillingParty,
                    AXLocation: null,
                });

                if (this.ICBillingPartyList && this.ICBillingPartyList.length > 0) {
                    this.ICBillingPartyList = this.ICBillingPartyList.sort((a, b) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
                    let indexDirect = this.ICBillingPartyList.findIndex((i) => i.label == this.Billing.BillingParty);
                    if (indexDirect > -1)
                        this.ICBillingPartyList.unshift(this.ICBillingPartyList.splice(indexDirect, 1)[0]);
                }

                if (this.Billing.BillingParty != null && !(this.Billing.BillingParty.toString().indexOf('Pending Approval') > -1)) {
                    this.BillingForm = true;
                    this.IsBillingInfoDisabled = false;
                }

                if (this.Billing.BillingParty != null && this.Billing.BillingParty.toString().indexOf('Pending Approval') > -1) {
                    this.UnapprovedHFMcode = true;
                    this.ShowAddProduct = false;
                    this.IsBillingInfoDisabled = true;
                    this.BillingForm = false;
                }
            } else {
                this.BillingForm = true;
            }

        }

    },
    watch: {
        "clientinfopageone.Entity":function(){
                this.Billing.AORClientCode = '';
                this.Billing.AORClientName = '';
                this.Billing.AORClientAgency = '';
        },
        "Billing.IsThisCostToClient": function (val) {
            if (val == "true") {
                this.CostoClient = true;
            } else {
                this.CostoClient = false;
            }
            this.Billing.ProgrammaticBundled = null;
        },

        "Billing.AORClientAgency": function () {
            let index = this.EntityList.findIndex(value => {
                return value.value == this.Billing.AORClientAgency;
            });

            if (index > -1) {
                this.$set(this.Billing, "AORClientAgencyName", this.EntityList[index].label);
            } else {
                if (this.Billing.AORClientAgency != null && this.Billing.AORClientAgency.length > 0) {
                    this.$set(this.Billing, "AORClientAgencyName", "N/A");
                } else {
                    this.$set(this.Billing, "AORClientAgencyName", "");
                }
            }

        },
        BillingParty: {
            handler: function (newVal, oldVal) {
                alert(newVal);
                alert(oldVal);
            },
        },
        "Billing.Country": function (val) {
            let index = this.Countryilst.findIndex((item) => {
                return item.value == val;
            });
            this.Billing.CountryName = this.Countryilst[index].label;
        },
        "Billing.Currency": function (val) {
            let index = this.CurrencyList.findIndex((item) => {
                return item.value == val;
            });
            if (index > -1) {
                this.Billing.CurrencyName = this.CurrencyList[index].label;
            }
        },
        "Billing.DfltPayment": function (val) {
            let index = this.PaymentTermsList.findIndex((item) => {
                return item.value == val;
            });
            if (index > -1) {
                this.Billing.DefaultPaymentTermsName = this.PaymentTermsList[
                    index
                ].label;
            } else {
                this.Billing.DefaultPaymentTermsName = "";
            }
            if(this.CanShowScreenshotAttachment(val))
            {
                this.ShowScreenshot = true;
            }
            else{
                this.ShowScreenshot = false;
                this.Billing.Files = [];
            }
        },
        "Billing.BillingState": function (val) {
            let index = this.Stateilst.findIndex((item) => {
                return item.value == val;
            });
            if (index > -1) {
                this.Billing.StateName = this.Stateilst[index].label;
            } else {
                this.Billing.StateName = null;
            }
        },

        "Billing.StreetAddress": function (val) {
            if (val.length > 26) {
                this.Showaddressbillingexception = true;
                this.Billing.TruncatedBillingStreetAddress = this.Billing.StreetAddress.slice(
                    0,
                    26
                );
            } else {
                this.Billing.TruncatedBillingStreetAddress = null;
                this.Showaddressbillingexception = false;
            }
        },
        "clientinfopageone.IsEditDataLoaded": function (val) {
            if (this.IsEdit == true && val == true) {
                this.ManuallyUploadFiles(this.Billing.Files);
            }
        },
    },
    computed: {
        TimeOnlyOptionSelected() {
            let timeOnlyOptions = [
                "Time Only (Client Related Time MED)",
                "Time Only (Internal Project Related Time ADM)",
            ];
            return timeOnlyOptions.includes(this.Billing.BillingParty);
        },
        IsCountryUSA() {
            return this.Billing.Country != null ?
                this.Billing.Country == "78d70193e1eb493dafcf3f261b7759ef" :
                null;
        },
        IsPopupCountryUSA() {
            return this.BillingPopup.Country != null ?
                this.BillingPopup.Country == "78d70193e1eb493dafcf3f261b7759ef" :
                null;
        },
        ...mapGetters("client", {
            Billing: "ClientBillingInformations",
            PaymentTermsList: "PaymentTerms",
            ICBillingPartyList: "billingparties",
            clientinfopageone: "ClientBasicDetails",
            clientinfopagetwo: "ClientEularInfo",
        }),
        ShowAOR() {
            if (this.id == undefined && this.clientinfopageone.EntityName == "7110 - OMnet") {
                this.$set(this.Billing, "IsThisCostToClient", "true");
            }
            const entityName = this.clientinfopageone.EntityName;
            const agencyName = this.clientinfopageone.AgencyName;
            const showAORFields = EntityNames.includes(entityName) || (EntitiesAlongWithAgency.includes(entityName) && AgencyNames.includes(agencyName));
            this.$set(this.Billing, "ShowAORFields", showAORFields);
            return showAORFields;
        },
        IsEdit() {
            return this.id && !isNaN(this.id) && parseInt(this.id) > 0 ? true : false;
        },
    },
    beforeDestroy() {
        this.isdestroying = true;
    },

    methods: {
        ResetDirtyBillingPopup(prop) {
            this.$v.BillingPopup[prop].$reset();
        },
        ResetDirty(prop) {
            this.$v.Billing[prop].$reset();
        },
        SetDirty(property) {
            this.$v.Billing[property].$touch();
        },
        LoadEntityDropdownValues() {
            if (this.EntityList == null || this.EntityList.length < 1) {
                service
                    .getOriginalEntityRecords("Financial Entity")
                    .then((res) => {
                        if (res.data != null && res.data.length > 0) {
                            this.EntityList = res.data;
                            if (this.clientinfopageone.AgencyName != "Fan Connect") {
                                this.EntityList = res.data.filter(value => {
                                    return value.label.toLowerCase().indexOf('7110 - omnet') < 0;
                                })
                            }

                            if (this.EntityList.findIndex(item => item.label == "N/A") == -1) {
                                this.EntityList.push({
                                    label: 'N/A',
                                    value: 'N/A'
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        alert(err);
                    });
            } else {
                if (this.clientinfopageone.AgencyName != "Fan Connect") {
                    this.EntityList = this.EntityList.filter(value => {
                        return value.label.toLowerCase().indexOf('7110 - omnet') < 0;
                    })
                    if (this.EntityList.findIndex(item => item.label == "N/A") == -1) {
                        this.EntityList.push({
                            label: 'N/A',
                            value: 'N/A'
                        });
                    }
                } else {
                    let OmnetIndex = this.EntityList.findIndex(value => {
                        return value.label.toLowerCase().indexOf('7110 - omnet') > -1;
                    })
                    if (!OmnetIndex > -1) {
                        this.EntityList = this.EntityList.filter(value => {
                            return value.label.toLowerCase().indexOf('7110 - omnet') < 0;
                        })

                        this.EntityList.push({
                            "label": "7110 - OMnet",
                            "value": "cbf656c4-8835-40f3-84f3-5ca660ce5d9c",
                            "AXLocation": null
                        });
                        if (this.EntityList.findIndex(item => item.label == "N/A") == -1) {
                            this.EntityList.push({
                                label: 'N/A',
                                value: 'N/A'
                            });
                        }
                    }
                }

            }
        },
        CheckHFMCodeExist() {
            var inputObj = {
                HFMCode: this.BillingPopup.HfmEntityCode.toUpperCase(),
                referenceCode: "19ab7a9e-b36f-4bb3-80dc-63b52f8ad39c",
            };
            service
                .getCheckHfmCodeExist(inputObj)
                .then((res) => {
                    this.IsCodeExist = res.data;
                })
                .catch(() => {
                    this.IsCodeExist = false;
                });
        },
        LoadBillingParty() {
            var inputObj = {
                entityCode: this.clientinfopageone.EntityName.split("-")[0].trim()
            };
            this.$store.dispatch("client/GetBillingParty", inputObj)
                .then((res) => {
                    res.data.push({
                        label: "Direct",
                        value: "Direct",
                        AXLocation: null,
                    });

                    if (res.data && res.data.length > 0) {
                        res.data = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexDirect = res.data.findIndex((i) => i.label == "Direct");
                        if (indexDirect > -1)
                            res.data.unshift(res.data.splice(indexDirect, 1)[0]);
                    }
                    store.commit("client/loadBillingparty", res.data);
                })
                .catch((err) => {
                    alert(err);
                });
        },

        template: function () {
            return `<div class="dz-preview dz-file-preview" style='background-color:#ffffff !important;display:flex;'>

                <div class="dz-details">
                    <div class="dz-size" style="display:none;" ><span data-dz-size></span></div>
                    <div class="FileforNewClientxls-Copy" style="margin-left:20px;width:280px;cursor:default !important;" ><span style='word-break:break-all !important;font-size:14px !important;font-weight:400;font-stretch:normal;font-style: normal;line-height: 0.71;color: #00a1d2 !important; font-family:"Open Sans" !important;' data-dz-name></span></div>
                </div>
                <a>
                 <img data-dz-remove=""  style='' src='/icons/fileremove.svg'  @click="DeleteServerFile()" ></img>
                </a>
            </div>`;
        },

        async LoadNetworkRegion() {
            service
                .getAgencyNetworkRegions('Agency Network')
                .then((res) => {
                    this.NetworkRegion = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },

        ShowSpinner() {
            store.commit("progress/setProcessing", {
                root: true,
            });
        },
        StopSpinner() {
            store.commit("progress/removeProcessing", {
                root: true,
            });
        },

        AddressResetConfrim() {
            this.Billing.Attn = null;
            this.Billing.BillToClientName = null;
            this.Billing.BillingState = null;
            this.Billing.BillingZipCode = null;
            this.Billing.Country = null;
            this.Billing.CountryName = null;
            this.Billing.Currency = null;
            this.Billing.CurrencyName = null;
            this.Billing.DefaultPaymentTermsName = null;
            this.Billing.DfltPayment = null;
            this.Billing.StateName = null;
            this.ShowBillingPartyConfirmation = false;
            this.Billing.IsThisCostToClient = null;
            this.Billing.ProgrammaticBundled = null;
            this.Billing.City = null;
            this.Billing.StreetAddress = null;
            this.Billing.AddressLine1 = null;
            this.Billing.AddressLine2 = null;
            this.Billing.AORClientCode = null;
            this.Billing.AORClientName = null;
            this.ShowScreenshot = false;
            this.Billing.Files = [];
        },

        ValidateZipCode() {
            if (
                this.Billing.BillingZipCode && this.Billing.BillingZipCode.length != 0 &&
                this.Billing.BillingZipCode.length < 5
            ) {
                this.validationtextbox = true;
            } else {
                this.validationtextbox = false;
            }
        },

        ValidatePopupZipCode() {

            if (
                this.BillingPopup.ZipCodes && this.BillingPopup.ZipCodes.length != 0 &&
                this.BillingPopup.ZipCodes.length < 5
            ) {
                this.popupvalidationtextbox = true;
            } else {
                this.popupvalidationtextbox = false;
            }
        },

        AddressResetCancel() {
            this.ShowBillingPartyConfirmation = false;
        },

        showAddProductModal() {
            this.ShowAddProduct = true;
        },
        CancelAdd() {
            this.BillingPopup = {};
            this.ShowAddProduct = false;
        },

        AddProduct() {
            let ICForm = {};
            ICForm.ExpectedHFMCode = this.BillingPopup.HfmEntityCode;
            ICForm.AgencyName = this.BillingPopup.InterCompanyAgencyName;
            ICForm.AgencyNetworkRegion = this.BillingPopup.InterAgencyNw;
            ICForm.AgencyCountry = this.BillingPopup.Country;
            ICForm.AgencyStreetAddressLine1 = this.BillingPopup.InterAgencyStreetAddressLine1;
            ICForm.AgencyStreetAddressLine2 = this.BillingPopup.InterAgencyStreetAddressLine2
            ICForm.AgencyStreetAddress = this.BillingPopup.InterAgencyStreetAddress;
            ICForm.AgencyCity = this.BillingPopup.InterAgencyCitys;
            ICForm.AgencyState = this.BillingPopup.States;
            ICForm.AgencyZipCode = this.BillingPopup.ZipCodes;
            ICForm.AgencyContact = this.BillingPopup.InterAgencyContact;
            ICForm.AgencyContactEmail = this.BillingPopup.InterAgencyContactEmail;
            ICForm.ID = 0;
            ICForm.Requestor = "";
            ICForm.CreatedDate = new Date();
            ICForm.UpdatedDate = new Date();
            ICForm.CompletedHFMCode = "";
            ICForm.OmniflowProcessId = 0;
            ICForm.OmniflowProjectId = 0;
            ICForm.AdditionalInstructions = this.BillingPopup.AdditionalInstructions;
            window.console.log(JSON.stringify(ICForm));
            this.$store
                .dispatch("client/AddIC", ICForm)
                .then((res) => {
                    if (res.data.message != null) {
                        window.console.log('IC request ID' + res.data.message);
                        this.ShowSpinner();
                        this.ICRequestId = res.data.message;
                        localStorage.setItem('clienticId', this.ICRequestId);
                        if (this.$refs.myVueDropzone.dropzone.files.length > 0) {
                            this.$refs.myVueDropzone.dropzone.options.params = {
                                'fileuploadId': `${this.ICRequestId}`
                            }
                            this.$refs.myVueDropzone.dropzone.processQueue();
                        }
                    } else {
                        this.StopSpinner();
                        this.ShowAddProduct = false;
                    }
                })
                .catch((ex) => {
                    this.IsBillingInfoDisabled = false;
                    //localStorage.setItem('clienticId','');
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Adding InterCompany. ${ex}`
                    );
                });
        },

        UploadedtoDropzone() {
            this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
            window.console.log('Uploaded to dropzoe');
            window.console.log(this.ddl);
        },

        RemovedFromDropzone(file) {
            if (this.isdestroying != true) {
                this.ddl = this.$refs.myVueDropzone.dropzone.files.length;
                this.RemoveFileList.push(file.name);
                window.console.log(JSON.stringify(this.RemoveFileList));
            }
        },
        mountedmyVueDropzone() {
            if (this.IsEdit == true && this.FilesUploaded == false) {
                this.ManuallyUploadFiles(this.Billing.Files);
                this.$set(this.Billing, "Files", this.GetFilesObject());
            }
        },
        ScreenshotUploadedToDropzone() {
            this.$set(this.Billing, "Files", this.GetFilesObject());
        },
        ScreenshotRemovedFromDropzone() {
            this.$set(this.Billing, "Files", this.GetFilesObject());
        },
        GetFilesObject() {
            let files = [];
            if (this.$refs.screenshotVueDropzone.dropzone.files && this.$refs.screenshotVueDropzone.dropzone.files.length > 0) {
                let filesExists = this.$refs.screenshotVueDropzone.dropzone.files;
                for (let i = 0; i < filesExists.length; i++) {
                    files.push({
                        name: filesExists[i].name,file:filesExists[i]
                    });
                }
                return files;
            } else {
                return files;
            }
        },
        CanShowScreenshotAttachment(val){
            var result = false;
            if(val && val.length == 4){
                let paymentTerm = Number(val.substr(1))
                if(paymentTerm != "NaN" && paymentTerm > 30) {
                    result =true;
                }
            }
            return result;
        },
        ManuallyUploadFiles(files) {
            if (this.$refs.screenshotVueDropzone != null) {
                if (files != null && files.length > 0 && this.FilesUploaded == false) {
                    files.forEach((fileUploaded) => {
                        let file = {
                            size: fileUploaded.Length,
                            name: fileUploaded.Name,
                            type: fileUploaded.Extension,
                        };
                        var url = fileUploaded.FullName;
                        this.$refs.screenshotVueDropzone.manuallyAddFile(file, url);
                    });
                }
                this.FilesUploaded = true;
            }
        },
        addAnalysisId(result) {
            window.console.log('success result' + JSON.stringify(result));
            if (result.xhr.status == 200) {
                //this.ShowAddProduct = false;
                this.ICBillingPartyList.push({
                    label: this.BillingPopup.HfmEntityCode + "  (pending approval) ",
                    value: this.BillingPopup.HfmEntityCode + "  (pending approval) ",
                    AXLocation: null,
                });
                window.console.log(JSON.stringify(this.ICBillingPartyList));
                if (this.ICBillingPartyList && this.ICBillingPartyList.length > 0) {
                    this.ICBillingPartyList = this.ICBillingPartyList.sort((a, b) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
                    let indexDirect = this.ICBillingPartyList.findIndex((i) => i.label == this.BillingPopup.HfmEntityCode + "  (pending approval) ");
                    if (indexDirect > -1)
                        this.ICBillingPartyList.unshift(this.ICBillingPartyList.splice(indexDirect, 1)[0]);
                }

                this.Billing.BillingParty = this.ICBillingPartyList[0].value;
                this.UnapprovedHFMcode = true;
                this.ShowAddProduct = false;
                this.IsBillingInfoDisabled = true;
                this.BillingForm = false;
                this.StopSpinner();
                store.dispatch(
                    "progress/setSuccessMessage",
                    `The InterCompany request has been submitted successfully`
                );
            } else {
                window.console.log('error while uploading files');
            }
        },

        Uploaderror(result) {
            window.console.log('Error uploading2' + JSON.stringify(result));
            this.StopSpinner();
        },

        // sendingEvent(formData) {
        //   window.console.log('from sendnig even',localStorage.getItem('clienticId'));
        //   formData.append("ICfileuploadId", localStorage.getItem('clienticId'));
        //   //ICfileuploadId
        // },

        Cancel() {

            // if(this.ICRequestId)
            // {
            //   service
            //   .DeleteICRequest(this.ICRequestId)
            //   .then((res) => {
            //     window.console.log(JSON.stringify(res));
            //     this.$router.push("/");
            //   })
            //   .catch((ex) => {
            //     this.$store.dispatch(
            //       "progress/setErrorMessage",
            //       `${ex}`
            //     );
            //   });
            // }

            this.$router.push("/ClientProduct");
            // var guid = localStorage.getItem("UploadfileId");
            // if (guid != "") {
            //   service
            //     .removeDiretory(guid)
            //     .then(() => {
            //       this.$router.push("/");
            //       localStorage.setItem("UploadfileId", "");
            //     })
            //     .catch((err) => {
            //       alert(err);
            //       this.$router.push("/");
            //     });
            // } else {
            //   this.$router.push("/");
            // }

            //Remove the file from temp location
        },

        InitiateObject() {},
        async LoadStates() {
            service
                .getEntityRecords("States")
                .then((res) => {
                    this.Stateilst = res.data;
                })
                .catch((err) => {
                    alert(err);
                });
        },
        async LoadCurrencies() {
            service
                .getOriginalEntityRecords("Currencies")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.CurrencyList = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.CurrencyList.findIndex(
                            (i) => i.label == "USD  - US Dollar "
                        );
                        let indexCanada = this.CurrencyList.findIndex(
                            (i) => i.label == "CAD  - Canadian Dollar "
                        );
                        if (indexCanada > -1)
                            this.CurrencyList.unshift(
                                this.CurrencyList.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.CurrencyList.unshift(
                                this.CurrencyList.splice(indexUSA, 1)[0]
                            );
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        LoadDropdownValues() {
            this.LoadStates();
            this.LoadCurrencies();
            service
                .getEntityRecords("Markets")
                .then((res) => {
                    if (res.data && res.data.length > 0) {
                        this.Countryilst = res.data.sort((a, b) =>
                            a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                        );
                        let indexUSA = this.Countryilst.findIndex(
                            (i) => i.label == "USA - United States"
                        );
                        let indexCanada = this.Countryilst.findIndex(
                            (i) => i.label == "CAN - Canada"
                        );
                        if (indexCanada > -1)
                            this.Countryilst.unshift(
                                this.Countryilst.splice(indexCanada, 1)[0]
                            );
                        if (indexUSA > -1)
                            this.Countryilst.unshift(this.Countryilst.splice(indexUSA, 1)[0]);
                    }
                })
                .catch((err) => {
                    alert(err);
                });
        },
        NavigateToRoute(name) {
            if (this.id) {
                this.$router.push({
                    name: `${name}`,
                    params: {
                        id: this.id,
                        isDraft: this.isDraft
                    }
                });
            } else {
                this.$router.push({
                    name: name
                });
            }
        },
        AddClientBillingInfo() {
            this.$parent.$emit("stepChanged", this.$route.meta.tabIndex);
            store.commit("client/NewClientBillingInfo", this.Billing);
            if (this.TimeOnlyOptionSelected) {
                this.NavigateToRoute("Product Info");
            } else {
                this.NavigateToRoute("Commission Info");
            }
            this.InitiateObject();
        },

        SaveasDraft() {
            this.$store
                .dispatch("client/SaveClientDraft", this.isDraft == true ? parseInt(this.id) : null)
                .then((res) => {
                    window.console.log(JSON.stringify(res));
                    if (res.status == 200) {
                        store.commit("progress/removeProcessing", {
                            root: true
                        });
                        //localStorage.setItem('clienticId','');
                        store.dispatch(
                            "progress/setSuccessMessage",
                            `The Client request have been saved as Draft successfully`
                        );

                        this.$router.push("/ClientProduct");
                    } else {
                        window.console.log('error while uploading files');
                    }

                })
                .catch((ex) => {
                    this.$store.dispatch(
                        "progress/setErrorMessage",
                        `Error while Saving Client as Draft. ${ex}`
                    );
                });
        },

        NavigateToEular() {
            this.NavigateToRoute("Euler Grade");
        },

        forminvalid() {
            if (this.ddl > 0) {
                return false;
            } else {
                return true;
            }
        },

        BillingChange() {
            let timeOnlyOptions = [
                "Time Only (Client Related Time MED)",
                "Time Only (Internal Project Related Time ADM)",
            ];
            let timeOnlySelected = timeOnlyOptions.includes(
                this.Billing.BillingParty
            );
            this.$parent.$emit("billingPartyChanged", timeOnlySelected);
            if (
                this.Billing.AddressLine1 != null ||
                this.Billing.AddressLine2 != null ||
                this.Billing.Attn != null ||
                this.Billing.BillToClientName != null ||
                this.Billing.BillingState != null ||
                this.Billing.BillingZipCode != null ||
                this.Billing.Country != null ||
                this.Billing.CountryName != null ||
                this.Billing.Currency != null ||
                this.Billing.CurrencyName != null ||
                this.Billing.DefaultPaymentTermsName != null ||
                this.Billing.DfltPayment != null ||
                (this.Billing.StateName != null && this.BillingForm != false)
            ) {
                this.ShowBillingPartyConfirmation = true;
            } else {
                this.BillingForm = true;
                this.ShowBillingPartyConfirmation = false;
            }
        },
    },
};
</script>

<style scoped>
/* .noterrorborder {
  border: #E9EEF0  !important;
} */

.Dont-see-the-what {
    padding-left: 4px !important;
    font-family: 'Open sans';
    font-size: 14px;
    color: #A2A9AD;

}

.Due-to-character-res {
    width: 426px;
    height: 15px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.25;
    letter-spacing: normal;
    color: #4d5161;
    margin-left: 10px;
}

.error-message {
    padding-left: 279px;
    height: 14px !important;
    font-size: 10px !important;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #df5678 !important;
    font-family: "Open Sans";
}

.SUGGESTED-ADDRESS {
    width: 179px;
    height: 19px;
    margin-left: 11px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #77a2bb !important;
}

.zipcodevalidationmsg {
    margin-top: -27px !important;
    margin-left: 278px !important;
    font-size: 10px !important;
    color: #df5678 !important;
    font-family: "Open Sans" !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    line-height: 0.8 !important;
    letter-spacing: normal !important;
    text-align: left !important;
}

.redtextbox {
    width: 415px !important;
    height: 35px !important;
    border: 1px solid #df5678 !important;
}

.notredtextbox {
    border: none !important;
}

.timeonly-text {
    font-family: "Open Sans" !important;
}

.floating-label-input {
    margin-top: 0px !important;
}

.fieldborder {
    padding-bottom: 15px !important;
}

.omniippopupclass {
    height: 35px !important;
    /* border:#E9EEF0 !important; */
    /* style="width:415px; height:35px;" */
}

.omniiaddressppopupclass {
    width: 491px !important;
    height: 35px !important;
    /* border:#E9EEF0 !important; */
    /* style="width:415px; height:35px;" */
}

.omniipclass {
    height: 35px !important;
    /* border: #e9eef0 !important; */
    /* style="width:415px; height:35px;" */
}

.omniipclassinteragencyemail {
    width: 351px !important;
    height: 35px !important;
}

.v-select {
    background-color: white !important;
}

.modal-title {
    font-family: "Open Sans" !important;
}

.modelmessage {
    font-family: "Open Sans" !important;
    font-size: 14px !important;
}

.REQUEST-NEW-INTERAGE {
    width: 214px;
    height: 9px;
    font-family: "Open Sans";
    font-size: 12px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.75;
    letter-spacing: normal;
    color: #00a1d2;
    margin-left: 60px !important;
}

.verticlaline {
    width: 740px;
    height: 0px;
    border: solid 1px #e2e2e2;
    margin-left: 106px;
}

.lbltxt {
    width: 300px;
    height: 8px;
    font-family: "Open Sans";
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 0.8;
    letter-spacing: normal;
    color: #4d5161;
    text-transform: uppercase;
}

.Select-a-billing-par {
    /* width: 321px; */
    height: 10px;
    font-family: "Open Sans";
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: 0.71;
    letter-spacing: normal;
    color: #a2a9ad;
}

.Additional-Text {
    width: 173px;
    height: 20px;
    font-family: "Open Sans" !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: normal;
    color: #4d5161;
}

.You-cannot-submit-th {
    height: 34px;
    font-family: "Open Sans" !important;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: italic;
    line-height: normal;
    letter-spacing: normal;
    color: #a2a9ad;
}

.Title-Text {
    width: 498px;
    height: 29px;
    font-family: "Open Sans";
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.81px;
    color: #4d5161;
}

.dz-max-files-reached {
    background-color: red;
}

.customdropzone>.dz-preview dz-file-preview {
    border-top: dashed 2px #e9eef0 !important;
}

.dz-image img {
    display: none !important;
}

.customdropzone {
    width: 350px !important;
    height: 44px !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.customBillingInfoDropzone {
    height: 100% !important;
    border-radius: 8px !important;
    border: dashed 2px #e9eef0 !important;
    background-color: #ffffff !important;
    cursor: pointer !important;
}

.Drag-and-drop-file-h {
    margin-left: 18px !important;
    margin-top: 14px !important;
    width: 237px !important;
    height: 10px !important;
    font-family: "Open Sans" !important;
    font-size: 14px !important;
    font-weight: normal !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 0.71 !important;
    letter-spacing: normal !important;
    color: #a2a9ad !important;
}

.draganddropspan {
    color: #00a1d2;
}

.filepond--drop-label.filepond--drop-label label {
    text-transform: inherit !important;
    color: darkgray !important;
    cursor: default !important;
    font-size: 0.875em !important;
    font-weight: 400 !important;
    text-align: center !important;
    line-height: 1.5 !important;
}
</style>
